<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <DepartmentAside asideColor="#e88383" title="胃腸科">
          <div class="img">
            <!-- <el-image :src="require(`@/assets/departments/internal.png`)"/> -->
          </div>
          <div class="detail1">
            <div class="content1">
              <div class="title1">主な急性疾患</div>
              <div class="text1">消化器の症状には、食欲不振、胃のもたれ感、胸やけ、吐き気や嘔吐、腹痛、膨満感、便秘や下痢など様々なものがあります。 市販薬に頼るのではなく、是非、一度受診されることをお薦めします。 また、がん専門医としての経験を活かし、がん治療後の経過観察、再発予防や生活習慣指導にも対応いたします。 栄養に関することでも結構です。</div>
            </div>
            <div class="content1">
              <div class="title1">主な慢性疾患</div>
              <div class="text1">食欲不振、胃のもたれ感、胸やけ、吐き気や嘔吐、腹痛、膨満感、便秘や下痢</div>
            </div>
            <div class="content1">
              <div class="title1">当クリニックは胃切除後障害対応施設になりました。</div>
              <div class="text1">
                「胃切除後障害」は、がんなどの疾患で胃の切除をしたあとに様々な胃の機能が失われることにより生じる障害を言います。 胃と脳をつなぐ「迷走神経」の切除や、内外分泌の低下による消化管の不調により、食事量や体重が減り体力が低下し、日常生活に支障をきたすことがあります。<br />
                そういった症状に合わせて食事や生活をマネジメントできるように、胃切除後の身体の不調を減らすための活動に取り組んでいます。<br />
                また、必要に応じて管理栄養士も相談に乗らせていただきます。
              </div>

            </div>
          </div>
        </DepartmentAside>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-md-and-up">
    <m-top/>

    <div class="m-title">胃腸科</div>
    <div class="m-main">
      <div class="m-detail">
        <div class="m-subtitle">主な急性疾患</div>
        <div class="m-content">消化器の症状には、食欲不振、胃のもたれ感、胸やけ、吐き気や嘔吐、腹痛、膨満感、便秘や下痢など様々なものがあります。 市販薬に頼るのではなく、是非、一度受診されることをお薦めします。 また、がん専門医としての経験を活かし、がん治療後の経過観察、再発予防や生活習慣指導にも対応いたします。 栄養に関することでも結構です。</div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">主な慢性疾患</div>
        <div class="m-content">食欲不振、胃のもたれ感、胸やけ、吐き気や嘔吐、腹痛、膨満感、便秘や下痢</div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">当クリニックは胃切除後障害対応施設になりました。</div>
        <div class="m-content">「胃切除後障害」は、がんなどの疾患で胃の切除をしたあとに様々な胃の機能が失われることにより生じる障害を言います。 胃と脳をつなぐ「迷走神経」の切除や、内外分泌の低下による消化管の不調により、食事量や体重が減り体力が低下し、日常生活に支障をきたすことがあります。</div>
        <div class="m-content">そういった症状に合わせて食事や生活をマネジメントできるように、胃切除後の身体の不調を減らすための活動に取り組んでいます。</div>
        <div class="m-content">また、必要に応じて管理栄養士も相談に乗らせていただきます。</div>
      </div>
    </div>


    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
export default {
  name: "Internal",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 30px;
}
.content1 .title1 {
  color: #e88383;
  margin-bottom: 10px;
}
.content1 .text1 {
  color: #535353;
  line-height: 1.5;
}
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #e88383;
  border-bottom: 2px solid #e88383;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #eb97ae;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}
</style>
